<template>
  <base-section id="404">
    <base-heading title="404" />

    <base-subheading
      class="primary--text"
      title="Vypadá to, že to, co hledáte..."
    />

    <base-subheading
      space="8"
      title="...tady není"
    />

    <router-link :to="{ name: 'Home' }">
      <base-img
        :src="require('@/assets/logo.svg')"
        class="mx-auto mt-n15"
        contain
        height="350"
        width="250"
      />
    </router-link>

    <div class="text-center mt-n15">
      <base-btn :to="{ name: 'Home' }">
        Správná stránka je tady
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
